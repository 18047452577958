$(".carousel").owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    items: 1,
    autoplay: true
});

$(".carouselVeiculo").owlCarousel({
    loop: false,
    nav: true,
    dots: false,
    items: 1,
    autoplay: false,
    thumbs: true,
    thumbsPrerendered: true,
    thumbImage: true
});