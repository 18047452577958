//Valida E-mail
function IsEmail(email) {
    var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
    var check = /@[\w\-]+\./;
    var checkend = /\.[a-zA-Z]{2,3}$/;
    if (
        email.search(exclude) != -1 ||
        email.search(check) == -1 ||
        email.search(checkend) == -1
    ) {
        return false;
    } else {
        return true;
    }
}

function recaptchaCallback() {
    $('#btnEnviarContato').removeAttr('disabled');
    $('#btnEnviarContatoVeiculo').removeAttr('disabled');
    $('#btnEnviaFinanciamento').removeAttr('disabled');
};

//Envia E-mail Contato
$("#formContato").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarContato").html(
        'Aguarde. Enviando.'
    );

    var nome = $("#nome").val();
    var email = $("#email").val();
    var telefone = $("#telefone").val();
    var mensagem = $("#mensagem").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        erros++;
    } else {
        $("#email").removeClass("errorField");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_contato.php",
            $("#formContato").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroContato").fadeOut("slow");
                    $("#formContato")[0].reset();
                    $(".sucessoContato").fadeIn("slow");
                    $("#btnEnviarContato").html("Enviar");
                }
            }
        );
    } else {
        $(".erroContato").fadeIn("slow");
        $("#btnEnviarContato").html("Enviar");
    }
});

//Envia E-mail Veículo
$("#formVeiculo").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarContatoVeiculo").html(
        'Aguarde. Enviando.'
    );

    var nome = $("#nome").val();
    var email = $("#email").val();
    var telefone = $("#telefone").val();
    var mensagem = $("#mensagem").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        erros++;
    } else {
        $("#email").removeClass("errorField");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_contato_veiculo.php",
            $("#formVeiculo").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroContatoVeiculo").fadeOut("slow");
                    $("#formVeiculo")[0].reset();
                    $(".sucessoContatoVeiculo").fadeIn("slow");
                    $("#btnEnviarContatoVeiculo").html("Enviar");
                }
            }
        );
    } else {
        $(".erroContatoVeiculo").fadeIn("slow");
        $("#btnEnviarContatoVeiculo").html("Enviar");
    }
});


//Envia E-mail Financiamento
$("#formFinanciamento").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarFinanciamento").html(
        'Aguarde. Enviando.'
    );

    $.post(
        url + "/email/form_financiamento.php",
        $("#formFinanciamento").serialize(),
        function(data) {
            if (data == "1") {
                $(".erroFinanciamento").fadeOut("slow");
                $("#formFinanciamento")[0].reset();
                $(".sucessoFinanciamento").fadeIn("slow");
                $("#btnEnviarFinanciamento").html("Enviar Proposta");
            }
        }
    );

});