$("#marcas").on('change', function() {
    let marca = $(this).val();

    $('#modelos').find('option').remove();
    $('#modelos').append($('<option>', {value:"0", text:"Carregando..."}));

    $.getJSON(url + "/ajax/filtra_modelos.php?marcas="+marca, function(dados) {

        if ((dados.total) && dados.total > 0) {
            console.log('Total de Resultados'+dados.total);
            $('#modelos').find('option').remove();
            $('#modelos').append($('<option>', {value:"0", text:"Selecione o Modelo"}));
            for (var i = 0; i < dados.total; i++) {
                let value = dados.modelos[i].value;
                let text = dados.modelos[i].name;
                $('#modelos').append($('<option>', {value:value, text:text}));
            }
        } else {
            $('#modelos').find('option').remove();
            $('#modelos').append($('<option>', {value:"0", text:"Selecione a Marca"}));
        }

    });

});