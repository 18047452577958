//Loader
jQuery(window).load(function() {
    "use strict";
    // Page Preloader
    jQuery("#loader")
        .delay(350)
        .fadeOut(function() {
            jQuery("body")
                .delay(350)
                .css({ overflow: "visible" });
        });
});