//=require _commom/jquery.min.js
//=require _commom/html5shiv.min.js
//=require _commom/respond.min.js

//=require ../../../../../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../../../../../node_modules/owl.carousel/dist/owl.carousel.js
//=require ../../../../../../node_modules/owl.carousel2.thumbs/src/owl.carousel2.thumbs.js
//=require ../../../../../../node_modules/jquery-mask-plugin/dist/jquery.mask.min.js
//=require ../../../../../../node_modules/jquery.localscroll/jquery.localScroll.min.js
//=require ../../../../../../node_modules/jquery.scrollto/jquery.scrollTo.min.js
//=require ../../../../../../node_modules/fancybox/dist/js/jquery.fancybox.js

//=require componentes/loader.js
//=require componentes/menu.js
//=require componentes/cep.js
//=require componentes/mascaras.js
//=require componentes/vitrine.js
//=require componentes/busca.js
//=require componentes/formularios.js

$('.fancybox').fancybox();